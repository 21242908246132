/* 
   Lemmings
   Joan Leon
   @nucliweb
  
   Thanks to Luifa Gonzalez for animation of Walker
   https://codepen.io/anon/pen/PqEjbG
*/

.lemming-box {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

$hair: #00b0b0;
$skin: #f0d0d0;
$clothes: #4040e0;
$grid: 10;

$colors: (
  1: $hair,
  2: $skin,
  3: $clothes
);

// Lemming Blocker -----------------------
$lemming-blocker-1: 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 2, 2, 1, 0, 0, 0, 2, 0, 0, 0, 2, 2, 0, 0, 0, 2,
  2, 2, 2, 2, 3, 3, 2, 2, 2, 2, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 0, 0, 0, 0, 0, 0, 3, 0, 0,
  3, 0, 0, 0, 0, 0, 2, 2, 0, 0, 2, 2, 0, 0;

$lemming-blocker-2: 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 2, 2, 1, 0, 0, 0, 2, 0, 0, 0, 2, 2, 0, 0, 0, 2,
  2, 2, 2, 2, 3, 3, 2, 2, 2, 2, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 0, 0, 0, 0, 0, 0, 3, 0, 0,
  3, 2, 0, 0, 0, 0, 2, 2, 0, 0, 2, 0, 0, 0;

// Lemming Walker -----------------------
$lemming-walker-1: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0,
  0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0;

$lemming-walker-2: 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0, 2, 0, 0, 0, 0, 0, 0, 3, 3, 0, 2, 0, 0, 0, 0, 0, 3, 3, 0, 2,
  0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0;

$lemming-walker-3: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 2, 2, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0, 0, 0, 0, 0, 0, 2, 2, 3, 3, 3, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3,
  0, 0, 0, 0, 0, 2, 2, 0, 0, 2, 2, 0, 0;

$lemming-walker-4: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 1, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 2, 3, 3, 3, 3,
  0, 0, 0, 0, 0, 2, 0, 0, 2, 2, 0, 0, 0;

$lemming-walker-5: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 2, 3, 3, 0,
  0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0;

$lemming-walker-6: 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 2, 0, 0, 0, 0, 0, 0, 3, 3, 0, 2, 0, 0, 0, 0, 0, 3, 3, 0, 2,
  0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0;

$lemming-walker-7: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 2, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3,
  0, 0, 0, 0, 0, 2, 2, 0, 0, 2, 2, 0, 0;

$lemming-walker-8: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 1, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 0, 0, 0, 0,
  0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 2, 3, 3, 3, 3,
  0, 0, 0, 0, 0, 2, 0, 0, 2, 2, 0, 0, 0;

@function lemming($lemming-list, $shadow-count: $grid) {
  $shadows: ();
  $row: 0;
  $col: 1;

  @for $i from 1 through length($lemming-list) {
    @if (nth($lemming-list, $i) > 0) {
      $shadows: append($shadows, ($col * 1px) ($row * 1px) 0 map-get($colors, nth($lemming-list, $i)), 'comma');
    }

    @if ($i % $grid == 0) {
      $row: $row + 1;
      $col: 0;
    }

    $col: $col + 1;
  }

  @return $shadows;
}

.grid {
  display: flex;
}

.lemming--blocker {
  width: 1px;
  height: 1px;
  margin: 10px;

  transform: scale(3);

  animation: blocker 1.2s step-start infinite;
  box-shadow: lemming($lemming-blocker-1);
}

.lemming--walker {
  width: 1px;
  height: 1px;
  margin: 10px;

  transform: scale(3);

  animation: walker 1.2s step-start infinite;
  box-shadow: lemming($lemming-walker-1);
}

@keyframes blocker {
  50% {
    box-shadow: lemming($lemming-blocker-2);
  }
}

@keyframes walker {
  12% {
    box-shadow: lemming($lemming-walker-2);
  }
  25% {
    box-shadow: lemming($lemming-walker-3);
  }
  37% {
    box-shadow: lemming($lemming-walker-4);
  }
  50% {
    box-shadow: lemming($lemming-walker-5);
  }
  62% {
    box-shadow: lemming($lemming-walker-6);
  }
  75% {
    box-shadow: lemming($lemming-walker-7);
  }
  87% {
    box-shadow: lemming($lemming-walker-8);
  }
}

@keyframes walker {
  12% {
    box-shadow: lemming($lemming-walker-2);
  }
  25% {
    box-shadow: lemming($lemming-walker-3);
  }
  37% {
    box-shadow: lemming($lemming-walker-4);
  }
  50% {
    box-shadow: lemming($lemming-walker-5);
  }
  62% {
    box-shadow: lemming($lemming-walker-6);
  }
  75% {
    box-shadow: lemming($lemming-walker-7);
  }
  87% {
    box-shadow: lemming($lemming-walker-8);
  }
}
