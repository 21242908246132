html {
  background-color: #c0c0c0;
}
html,
body {
  padding: 0;
  margin: 0;
}

h2,
h3,
h4 {
  margin-bottom: 1rem;
  font-family: 'Pixelated MS Sans Serif', Arial;
  -webkit-font-smoothing: none;
}
code *,
summary {
  font-family: 'Pixelated MS Sans Serif', Arial;
  -webkit-font-smoothing: none;
}

h2 {
  font-size: 32px;
}
h3 {
  font-size: 18px;
  display: flex;
  align-items: center;
}
h4 {
  font-size: 16px;
}

.center {
  margin: 1rem auto;
  width: 640px;
  border: 3px solid #5f5fdf;
  padding: 10px;
}

figure {
  border: 1px solid black;
  position: absolute;
  right: 0;
  top: 10px;
  padding: 0;
  margin: 0;
  height: 230px;
}

img.profile {
  width: 200px;
  height: 230px;
}

@media only screen and (max-width: 576px) {
  figure {
    position: relative;
    width: 200px;
    height: 230px;
  }
  .w400 {
    height: 26px;
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 376px) {
  .center {
    width: 340px;
    margin: 0;
    padding: 7px;
  }
}

@media only screen and (min-width: 377px) and (max-width: 428px) {
  .center {
    width: 360px;
    margin: 0;
  }
}

@media only screen and (min-width: 577px) {
  .w400 {
    width: 400px;
    height: 26px;
    display: flex;
    flex-direction: row;
  }
}

.Modal {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 306px;
  height: 188px;
}

.Modal2 {
  position: absolute;
  background-color: transparent;
  left: 40px;
  right: 40px;
  bottom: 40px;
}

.Modal3 {
  position: absolute;
  background-color: transparent;
  top: 40px;
  right: 40px;
  bottom: 40px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.sliderLabel {
  margin-right: 8px;
  white-space: normal;
  width: 180px;
}

.break {
  white-space: break-spaces;
}

.overlay {
  width: 200px;
  height: 230px;
  left: 0;
  top: 0;
  background-color: #c0c0c0;
  position: absolute;
  animation-name: loader;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}

@keyframes loader {
  0% {
    top: 0px;
    height: 230px;
  }
  25% {
    top: 20px;
    height: 210px;
  }
  50% {
    top: 80px;
    height: 150px;
  }
  99% {
    top: 225px;
    height: 5px;
    background-color: #c0c0c0;
  }
  100% {
    top: 225px;
    height: 5px;
    background-color: white;
  }
}

.number-transition {
  transition: 1s ease-in-out;
}
